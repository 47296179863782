<template>
  <ml-container v-if="!loading">
    <div>
      <h2 class="tw-text-sm tw-leading-relaxed tw-font-semibold tw-mb-4">Couple</h2>
      <ml-grid>
        <div class="tw-col-span-2">
          <div>
            <div class="tw-h-80 tw-relative" v-if="wedding.images.length > 0">
              <img :src="wedding.images[0].src" alt="" class="tw-object-cover tw-h-80 tw-w-full">
              <span @click="destroyImage(wedding.images[0].id)"
                    class="tw-absolute tw-shadow tw-h-6 tw-w-6 tw-cursor-pointer tw-rounded-full tw-bg-white tw-flex tw-items-center tw-justify-center tw-right-4 tw-top-4">
                <x-icon class="tw-w-3 tw-h-3"/>
              </span>

            </div>

            <div v-if="wedding.images.length === 0">
              <v-file-input
                  @change="onFileSelected"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="Couples photo"
                  prepend-icon="mdi-camera"
                  label="Image"
                  v-model="file"
              ></v-file-input>
              <v-spacer v-if="file"/>
              <v-progress-circular
                  :width="1"
                  color="blue"
                  indeterminate
                  v-if="file"
              ></v-progress-circular>
            </div>
            <v-progress-linear v-if="file" :value="imageUpload"></v-progress-linear>
          </div>
        </div>
        <div class="tw-col-span-6">
          <div class="tw-grid sm:tw-grid-cols-2 tw-gap-y-6 tw-gap-x-8">
            <div v-if="wedding.newlyweds.length < 2">
              <div class="tw-border tw-rounded-xl tw-border-gray-n1 tw-p-4 tw-flex tw-items-center">
                <span class="tw-text-sm tw-font-semibold tw-leading-relaxed tw-text-gray-n4">Add partner's info</span>
                <span @click="addNewlywed()"
                      class="tw-cursor-pointer tw-bg-black tw-rounded-xl tw-w-14 tw-h-14 tw-flex tw-items-center tw-justify-center tw-ml-auto tw-text-white">
                  <plus-icon class="tw-w-6 tw-w-6"/>
                </span>
              </div>
            </div>
            <div v-for="newlywed in wedding.newlyweds" v-bind:key="newlywed.id">
              <div class="tw-border tw-rounded-xl tw-border-gray-n1 tw-p-4 tw-flex tw-items-center">
                <div class="tw-flex tw-flex-col tw-space-y-1">
                  <span class="tw-text-sm tw-font-semibold tw-leading-relaxed tw-text-gray-n2">{{
                      newlywed.type.name
                    }}</span>
                  <span class="tw-text-sm tw-font-semibold tw-leading-relaxed tw-text-gray-n4">{{ newlywed.first_name }} {{
                      newlywed.last_name
                    }}</span>
                </div>
                <span @click="removeNewlywed(newlywed)"
                      class="tw-cursor-pointer tw-bg-gray-n0 tw-rounded-xl tw-w-14 tw-h-14 tw-flex tw-items-center tw-justify-center tw-ml-auto tw-text-gray-n4">
                  <trash2-icon class="tw-w-6 tw-w-6"/>
                </span>
                <span @click="updateNewlywed(newlywed)"
                      class="tw-cursor-pointer tw-bg-gray-n0 tw-rounded-xl tw-w-14 tw-h-14 tw-flex tw-items-center tw-justify-center tw-ml-3 tw-text-gray-n4">
                  <edit2-icon class="tw-w-6 tw-w-6"/>
                </span>
              </div>
              <!--              <div>
                            <v-btn
                                  v-if="newlywed.purchasing_power"
                                  text
                                  elevation="0"
                                  disabled
                                  x-small
                              >
                                {{ $t('event.purchasing') }}
                                <v-icon small color="green">mdi-currency-</v-icon>
                              </v-btn>
                              <v-spacer/>
                              <v-btn
                                  @click="removeNewlywed(newlywed)"
                                  icon>
                                <v-icon color="gray">mdi-delete</v-icon>
                              </v-btn>

                            </div>-->
            </div>
            <div>
              <v-dialog
                  ref="dialog"
                  v-model="menu"
                  :return-value.sync="wedding.date"
                  width="320px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on"
                       class="tw-cursor-pointer tw-border tw-rounded-xl tw-border-gray-n1 tw-p-4 tw-flex tw-items-center">
                    <div class="tw-flex tw-flex-col tw-space-y-1">
                      <span class="tw-text-sm tw-font-semibold tw-leading-relaxed tw-text-gray-n2">{{
                          $t('event.event_date')
                        }}</span>
                      <span v-if="wedding.date"
                            class="tw-text-sm tw-leading-relaxed tw-text-gray-n4">{{ dateFormat(wedding.date) }}</span>
                    </div>
                    <span
                        class="tw-bg-black tw-rounded-xl tw-w-14 tw-h-14 tw-flex tw-items-center tw-justify-center tw-ml-auto tw-text-white">
                      <calendar-icon class="tw-w-6 tw-w-6"/>
                    </span>
                  </div>
                </template>
                <div class="tw-flex tw-flex-col tw-items-center tw-p-6 tw-bg-white">
                  <p class="tw-text-xl tw-font-semibold tw-mb-4 tw-self-center">Choose a date</p>
                  <v-calendar-io
                      is-expanded class="vc-border-none" :min-date="new Date()"
                      :attributes="attributes" @dayclick="onDayClick"
                  />
                  <Button class="tw-mt-4 tw-w-full" type="p_dark"
                          @click="$refs.dialog.save(wedding.date);updateWedding(wedding);menu = false">
                    Choose this date
                  </Button>
                </div>

              </v-dialog>

            </div>

            <div>
              <div class="tw-border tw-rounded-xl tw-border-gray-n1 tw-p-4 tw-flex tw-items-center">
                <div class="tw-flex tw-flex-col tw-space-y-1">
                  <label for="webpage_link"
                         class="tw-mb-0 tw-text-sm tw-font-semibold tw-leading-relaxed tw-text-gray-n2">Webpage
                    link</label>
                  <span class="tw-text-sm tw-leading-relaxed tw-text-gray-n4">
                    <input id="webpage_link" @change="updateWedding(wedding)" v-model="wedding.webpage_link" type="text"
                           class="tw-p-0 tw-text-sm tw-text-red tw-placeholder-gray-n3 tw-leading-relaxed"
                           placeholder="Enter your website link">
                  </span>
                </div>
                <span
                    class="tw-bg-gray-n0 tw-rounded-xl tw-w-14 tw-h-14 tw-flex tw-items-center tw-justify-center tw-ml-auto tw-text-gray-n3">
                  <arrow-up-right-icon class="tw-w-6 tw-w-6"/>
                </span>
              </div>
            </div>

            <div>
              <div class="tw-border tw-rounded-xl tw-border-gray-n1 tw-p-4 tw-flex tw-items-center">
                <div class="tw-flex tw-flex-col tw-space-y-1">
                  <label for="access_code"
                         class="tw-mb-0 tw-text-sm tw-font-semibold tw-leading-relaxed tw-text-gray-n2">Access
                    code</label>
                  <span class="tw-text-sm tw-leading-relaxed tw-text-gray-n4">
                    <input id="access_code" v-model="wedding.access_code" type="text"
                           @change="updateWedding(wedding)"
                           class="tw-p-0 tw-text-sm tw-text-gray-n4 tw-placeholder-gray-n3 tw-leading-relaxed"
                           placeholder="Enter your website access code">
                  </span>
                </div>
                <span
                    class="tw-bg-gray-n0 tw-rounded-xl tw-w-14 tw-h-14 tw-flex tw-items-center tw-justify-center tw-ml-auto tw-text-gray-n3">
                  <copy-icon class="tw-w-6 tw-w-6"/>
                </span>
              </div>
            </div>
          </div>
        </div>
      </ml-grid>

      <h2 class="tw-text-sm tw-leading-relaxed tw-font-semibold tw-mb-4 tw-mt-8">Wedding team</h2>
      <div>
        <div class="tw-border tw-rounded-xl tw-border-gray-n1 tw-p-4 tw-flex sm:tw-items-center tw-flex-col sm:tw-flex-row">
          <div class="tw-flex tw-flex-col tw-space-y-1 tw-flex-grow sm:tw-mr-6">
            <label for="message_guests" class="tw-mb-0 tw-text-sm tw-font-semibold tw-leading-relaxed tw-text-gray-n2">Message
              to guests</label>
            <span class="tw-text-sm tw-leading-relaxed tw-text-gray-n4">
              <textarea id="message_guests" rows="3"
                        @change="updateWedding(wedding)"
                        v-model="wedding.description"
                        class="tw-w-full tw-p-0 tw-text-sm tw-text-gray-n4 tw-placeholder-gray-n3 tw-leading-relaxed"
                        placeholder="Enter your message for guests"></textarea>
            </span>
          </div>
          <span @click="people = !people"
                class="tw-cursor-pointer tw-bg-black tw-rounded-xl tw-px-20 tw-h-14 tw-flex tw-items-center tw-justify-center sm:tw-ml-auto tw-text-white tw-font-semibold">
            Add people
          </span>
        </div>
      </div>
      <div class="tw-mt-6 tw-grid sm:tw-grid-cols-6">
        <div class="tw-col-span-2" v-for="(guest, index) in wedding.guests" v-bind:key="index">
          <div class="tw-border tw-rounded-xl tw-border-gray-n1 tw-p-4 tw-flex tw-items-center tw-ml-1 tw-mb-1">
            <div class="tw-flex tw-flex-col tw-space-y-1">
              <span class="tw-text-sm tw-font-semibold tw-leading-relaxed tw-text-gray-n2">{{ guest.type.name }}</span>
              <span class="tw-text-sm tw-font-semibold tw-leading-relaxed tw-text-gray-n4">{{
                  guest.first_name
                }} {{ guest.last_name }}</span>
            </div>
            <span @click="destroy(guest)"
                  class="tw-cursor-pointer tw-bg-gray-n0 tw-rounded-xl tw-w-14 tw-h-14 tw-flex tw-items-center tw-justify-center tw-ml-auto tw-text-gray-n4">
              <trash2-icon class="tw-w-6 tw-w-6"/>
            </span>
            <span @click="update(guest)"
                  class="tw-cursor-pointer tw-bg-gray-n0 tw-rounded-xl tw-w-14 tw-h-14 tw-flex tw-items-center tw-justify-center tw-ml-3 tw-text-gray-n4">
              <edit2-icon class="tw-w-6 tw-w-6"/>
            </span>
          </div>
        </div>
      </div>
      <v-dialog
          v-model="people"
          persistent
          max-width="580"
      >
        <div v-if="people">
          <div>
            <div class="tw-bg-white tw-p-10 tw-text-black">

              <div class="tw-flex tw-items-center tw-mb-11">
                <span class="tw-text-current tw-mr-3.5"><plus-circle-icon class="tw-h-5 tw-w-5"/></span>
                <span class="tw-text-xl">{{
                    newlywedManage ? $t('event.add_partner') : $t('event.add_team_member')
                  }}</span>
              </div>
              <div>
                <v-form ref="form" v-model="valid" :lazy-validation="lazy" class="tw-space-y-4">
                  <div v-if="newlywedManage">
                    <label class="tw-mb-0 tw-block tw-text-sm tw-font-semibold tw-text-gray-n3 tw-leading-relaxed">
                      Partner
                    </label>
                    <ml-error-msg :msg="errors['type_id']"/>
                    <div class="tw-mt-2 tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 sm:tw-gap-x-4 tw-mb-6">

                      <label
                          :class="item.type_id === 1 ? 'tw-border-black tw-bg-gray-n0' : 'tw-border-gray-n1 tw-bg-white'"
                          class="tw-mb-0 tw-relative tw-border tw-rounded-xl tw-p-4 tw-flex tw-cursor-pointer focus:tw-outline-none">
                        <input v-model.number="item.type_id" type="radio" name="project-type" value="1"
                               class="tw-sr-only" aria-labelledby="project-type-0-label"
                               aria-describedby="project-type-0-description-0">
                        <span class="tw-flex-1 tw-flex">
                          <span id="project-type-0-label" class="tw-block tw-text-xs tw-font-semibold tw-text-gray-900">
                            Bride
                          </span>
                        </span>

                        <svg v-if="item.type_id === 1" width="16" height="16" viewBox="-1 -1 18 18" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
                              fill="#E1E1E1"/>
                          <circle cx="8" cy="8" r="6" fill="#2D2D2D"/>
                          <path
                              d="M8 15C4.13401 15 1 11.866 1 8H-1C-1 12.9706 3.02944 17 8 17V15ZM15 8C15 11.866 11.866 15 8 15V17C12.9706 17 17 12.9706 17 8H15ZM8 1C11.866 1 15 4.13401 15 8H17C17 3.02944 12.9706 -1 8 -1V1ZM8 -1C3.02944 -1 -1 3.02944 -1 8H1C1 4.13401 4.13401 1 8 1V-1Z"
                              fill="#2D2D2D"/>
                        </svg>
                        <span v-else class="tw-h-4 tw-w-4 tw-border-2 tw-border-gray-n1 tw-rounded-full">
                        </span>
                      </label>
                      <label
                          :class="item.type_id === 2 ? 'tw-border-black tw-bg-gray-n0' : 'tw-border-gray-n1 tw-bg-white'"
                          class="tw-mb-0 tw-relative tw-border tw-rounded-xl tw-p-4 tw-flex tw-cursor-pointer focus:tw-outline-none">
                        <input v-model.number="item.type_id" type="radio" name="project-type" value="2"
                               class="tw-sr-only" aria-labelledby="project-type-1-label"
                               aria-describedby="project-type-1-description-0 project-type-1-description-1">
                        <span class="tw-flex-1 tw-flex">
                          <span class="tw-flex tw-flex-col tw-text-black">
                            <span id="project-type-1-label" class="tw-block tw-text-xs tw-font-semibold">
                              Groom
                            </span>
                          </span>
                        </span>

                        <svg v-if="item.type_id === 2" width="16" height="16" viewBox="-1 -1 18 18" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
                              fill="#E1E1E1"/>
                          <circle cx="8" cy="8" r="6" fill="#2D2D2D"/>
                          <path
                              d="M8 15C4.13401 15 1 11.866 1 8H-1C-1 12.9706 3.02944 17 8 17V15ZM15 8C15 11.866 11.866 15 8 15V17C12.9706 17 17 12.9706 17 8H15ZM8 1C11.866 1 15 4.13401 15 8H17C17 3.02944 12.9706 -1 8 -1V1ZM8 -1C3.02944 -1 -1 3.02944 -1 8H1C1 4.13401 4.13401 1 8 1V-1Z"
                              fill="#2D2D2D"/>
                        </svg>
                        <span v-else class="tw-h-4 tw-w-4 tw-border-2 tw-border-gray-n1 tw-rounded-full">
                        </span>
                      </label>
                    </div>
                  </div>
                  <div v-else>
                    <label class="tw-mb-0 tw-block tw-text-sm tw-font-semibold tw-text-gray-n3 tw-leading-relaxed">
                      Team member
                    </label>
                    <div class="tw-mt-2 tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 sm:tw-gap-x-4 tw-mb-6 tw-gap-y-2">
                      <label v-for="type in types" v-bind:key="type.id"
                             :class="item.type_id === type.id ? 'tw-border-black tw-bg-gray-n0' : 'tw-border-gray-n1 tw-bg-white'"
                             class="tw-mb-0 tw-relative tw-border tw-rounded-xl tw-p-4 tw-flex tw-cursor-pointer focus:tw-outline-none">
                        <input v-model.number="item.type_id" type="radio" name="project-type" :value="type.id"
                               class="tw-sr-only" :aria-labelledby="'project-type-' + type.id + '-label'"
                               :aria-describedby="'project-type-' + type.id + '-description'">
                        <span class="tw-flex-1 tw-flex">
                          <span :id="'project-type-' + type.id + '-label'"
                                class="tw-block tw-text-xs tw-font-semibold tw-text-gray-900">
                            {{ type.name }}
                          </span>
                        </span>

                        <svg v-if="item.type_id === type.id" width="16" height="16" viewBox="-1 -1 18 18" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
                              fill="#E1E1E1"/>
                          <circle cx="8" cy="8" r="6" fill="#2D2D2D"/>
                          <path
                              d="M8 15C4.13401 15 1 11.866 1 8H-1C-1 12.9706 3.02944 17 8 17V15ZM15 8C15 11.866 11.866 15 8 15V17C12.9706 17 17 12.9706 17 8H15ZM8 1C11.866 1 15 4.13401 15 8H17C17 3.02944 12.9706 -1 8 -1V1ZM8 -1C3.02944 -1 -1 3.02944 -1 8H1C1 4.13401 4.13401 1 8 1V-1Z"
                              fill="#2D2D2D"/>
                        </svg>
                        <span v-else class="tw-h-4 tw-w-4 tw-border-2 tw-border-gray-n1 tw-rounded-full">
                        </span>

                      </label>
                      <ml-error-msg :msg="errors['type_id']"/>
                    </div>
                  </div>

                  <div>
                    <label for="first_name"
                           class="tw-mb-0 tw-block tw-text-sm tw-font-semibold tw-text-gray-n3 tw-leading-relaxed">
                      First name
                    </label>
                    <div class="tw-mt-2">
                      <input v-model="item.first_name" autofocus id="first_name" name="first_name" type="text"
                             autocomplete="first_name" required
                             class="tw-appearance-none tw-block tw-w-full tw-px-4 tw-py-3 tw-border tw-border-solid tw-border-gray-n1 tw-rounded-xl tw-placeholder-gray-n1 focus:tw-outline-none focus:tw-ring-black focus:border-black sm:tw-text-sm">
                      <ml-error-msg :msg="errors['first_name']"/>
                    </div>
                  </div>
                  <div>
                    <label for="last_name"
                           class="tw-mb-0 tw-block tw-text-sm tw-font-semibold tw-text-gray-n3 tw-leading-relaxed">
                      Last name
                    </label>
                    <div class="tw-mt-2">
                      <input v-model="item.last_name" autofocus id="last_name" name="last_name" type="text"
                             autocomplete="last_name" required
                             class="tw-appearance-none tw-block tw-w-full tw-px-4 tw-py-3 tw-border tw-border-solid tw-border-gray-n1 tw-rounded-xl tw-placeholder-gray-n1 focus:tw-outline-none focus:tw-ring-black focus:border-black sm:tw-text-sm">
                      <ml-error-msg :msg="errors['last_name']"/>
                    </div>
                  </div>
                  <div>
                    <label for="email"
                           class="tw-mb-0 tw-block tw-text-sm tw-font-semibold tw-text-gray-n3 tw-leading-relaxed">
                      Email
                    </label>
                    <div class="tw-mt-2">
                      <input v-model="item.email" autofocus id="email" name="email" type="email" autocomplete="email"
                             required
                             class="tw-appearance-none tw-block tw-w-full tw-px-4 tw-py-3 tw-border tw-border-solid tw-border-gray-n1 tw-rounded-xl tw-placeholder-gray-n1 focus:tw-outline-none focus:tw-ring-black focus:border-black sm:tw-text-sm">
                      <ml-error-msg :msg="errors['email']"/>
                    </div>
                  </div>
                  <!--                      <v-select
                                            v-if="!newlywedManage"
                                            v-model="item.type_id"
                                            :items="types"
                                            :rules="[rules.required]"
                                            item-text="name"
                                            item-value="id"
                                            label="* Type"
                                            :readonly="newlywedManage"
                                        ></v-select>-->
                  <v-checkbox
                      :ripple="false"
                      color="#2D2D2D"
                      class="tw-text-radio-sm"
                      :label="$t('event.purchasing_power')"
                      v-model="item.purchasing_power"
                  ></v-checkbox>

                </v-form>
                <span v-if="error" class="tw-text-red">
                  {{ error.message }}
                  {{ error.errors }}
                </span>
              </div>

              <div class="tw-mt-10 tw-grid tw-grid-cols-2 tw-gap-x-4">


                <span @click="clear"
                      class="tw-cursor-pointer tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-solid tw-border-black tw-text-base tw-font-medium tw-rounded-xl tw-text-black tw-bg-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
                  Cancel
                </span>
                <button
                    v-if="!this.edit"
                    class="tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-gray-n4 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4"
                    @click="addGuest(wedding.id)"
                >
                  Add
                </button>
                <button
                    v-else
                    class="tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-gray-n4 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4"
                    @click="updateWeddingGuest(item)"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </v-dialog>
    </div>

  </ml-container>
</template>

<script>
import {mapGetters} from 'vuex'
import MlContainer from "@/components/layout/MlContainer";
import MlGrid from "@/components/layout/MlGrid";
import {
  XIcon,
  PlusIcon,
  Edit2Icon,
  CalendarIcon,
  ArrowUpRightIcon,
  CopyIcon,
  Trash2Icon,
  PlusCircleIcon
} from "vue-feather-icons";
import MlErrorMsg from "@/components/layout/form/MlErrorMsg";
import Button from '@/components/Button.vue'

export default {
  components: {
    MlGrid,
    MlContainer,
    XIcon,
    PlusIcon,
    Edit2Icon,
    CalendarIcon,
    ArrowUpRightIcon,
    CopyIcon,
    Trash2Icon,
    PlusCircleIcon,
    MlErrorMsg,
    Button,
  },
  middleware: ['auth', 'user'],
  data() {
    return {
      autocomplete: null,
      showCode: false,
      imageUpload: 0,
      editDescription: false,
      newlywedManage: false,
      menu: false,
      people: false,
      edit: false,
      valid: true,
      lazy: true,
      types: [],
      errors: [],
      error: null,
      panel: [0, 1],
      wedding_guests: [],
      weddings: [],
      wedding: {
        images: [],
        newlyweds: [],
        date: null
      },
      newlyweds: [],
      loading: false,
      item: {
        event_id: null,
        type_id: null,
        first_name: '',
        last_name: ''
      },
      selectedFile: null,
      file: null,
      rules: {
        required: value => !!value || 'Required.'
      },
      emailRules: [
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
      ],
    }
  },
  mounted() {
    this.getTypes();
    //this.getWeddingGuests();
    //this.getWeddings();
    this.getWedding();
    //this.getNewlyweds();
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    attributes() {
      return [
        {
          highlight: {
            class: 'tw-bg-red',
          },
          dates: this.wedding.date,
        },
      ]
    },
  },
  watch: {},
  methods: {
    onDayClick(day) {
      this.wedding.date = day.id;
    },
    validateForm() {

      console.log("validate form...");
      this.errors = [];
      let label = '';

      if (!this.item.first_name) {
        label = "Name required.";
        this.errors.push(label);
        this.errors["first_name"] = label;
      }

      if (!this.item.last_name) {
        label = "Last name required.";
        this.errors.push(label);
        this.errors["last_name"] = label;
      }

      if (!this.item.email) {
        label = "Email required.";
        this.errors.push(label);
        this.errors["email"] = label;
      } else if (!this.validEmail(this.item.email)) {
        label = "Valid email required.";
        this.errors.push(label);
        this.errors["email"] = label;
      }

      if (!this.item.type_id) {
        label = "Type required.";
        this.errors.push(label);
        this.errors["type_id"] = label;
      }

      /*
        if (this.item.products instanceof Array && this.item.products.length === 0 || !this.item.products) {
          label = "Service & Goods required.";
          this.errors.push(label);
          this.errors["products"] = label;
        }
      */
      if (!this.errors.length) {
        this.valid = true;
        return true;
      }

      this.valid = false;
      return false;

    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    clear() {
      this.item = {
        type_id: 1
      };
      this.people = false;
      this.edit = false;
      this.newlywedManage = false;
      this.$refs.form.reset();
    },
    update(value) {
      this.item = value;
      this.people = true;
      this.edit = true;
      this.item.event_id = this.wedding.id;
      this.item.type_id = value.type.id;
    },
    updateNewlywed(value) {
      this.newlywedManage = true;
      this.update(value);
    },
    async removeNewlywed(newlywed) {
      await this.destroy(newlywed);
    },
    addNewlywed(type) {

      this.item.type_id = type;
      if (!this.wedding) {
        return;
      }
      this.item.event_id = this.wedding.id;
      this.people = true;
      this.newlywedManage = true;
      //this.addGuest(this.item.event_id);
    },
    onFileSelected(event) {
      //console.log(event);
      if (event == null) return;
      //this.selectedFile = event.target.files[0];
      this.selectedFile = event;
      this.onUpload();
    },
    onUpload() {
      const formData = new FormData();
      formData.append('image', this.selectedFile, this.selectedFile.name);
      let uri = '/api/events/images/' + this.wedding.id;
      this.$http.post(uri, formData, {
        onUploadProgress: uploadEvent => {
          this.imageUpload = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
          console.log('Upload progress: ' + Math.round(uploadEvent.loaded / uploadEvent.total * 100));
        }
      })
          .then((response) => {
            this.wedding.images = response.data.images,
                //this.item.images = this.item.images.concat(response.data.images),
                this.$toast.success(response.data.message, "Success")
            //this.$refs.inputFile.reset();
            this.file = null;
          });
    },
    destroyImage(id) {
      this.$toast.question('Are you sure about that?', "Confirm", {
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: 'once',
        id: 'address',
        zindex: 999,
        title: 'Hey',
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', (instance, toast) => {

            this.onImageDelete(id);

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }, true],
          ['<button>NO</button>', function (instance, toast) {

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }],
        ]
      });
    },
    onImageDelete(id) {
      if (!this.wedding.id) return;
      let uri = '/api/events/' + this.wedding.id + '/images/' + id;
      this.$http.delete(uri)
          .then((response) => {
            //this.item.images.push(response.data.images),
            this.wedding.images = response.data.images,
                //this.item.images = response.data.images;
                this.$toast.success(response.data.message, "Success", {timeout: 1000});
          });

    },
    async addGuest(id) {
      //await this.$refs.form.validate();
      if (this.validateForm()) ;
      if (!this.valid) return;

      if (!id) {
        return;
      }
      this.item.event_id = id;
      let uri = '/api/event-guests';
      this.$http.post(uri, this.item)
          .then((response) => {
            this.refreshData(this.item.type_id),
                this.$toast.success(response.data.message, "Success"),
                this.clear()
          }).catch((error) => {
        this.error = error.data;
      });
    },
    updateWeddingGuest(value) {
      //console.log(value);
      if (this.validateForm()) ;
      if (!this.valid) return;

      let uri = '/api/event-guests/' + value.id;
      this.$http.patch(uri, value)
          .then((response) => {
            this.getWeddingGuests(),
                this.$toast.success(response.data.message, "Success")
            //this.clear()
          });
    },
    createWedding() {
      let uri = '/api/events';
      this.$http.post(uri)
          .then((response) => {
            this.getWedding(),
                this.$toast.success(response.data.message, "Success")
          });
    },
    deleteWedding(value) {
      let uri = '/api/events/' + value.id;
      this.$http.delete(uri)
          .then((response) => {
            this.wedding = {};
            this.$toast.success(response.data.message, "Success")
          });
    },
    removeWeddingGuest(value) {
      let uri = '/api/event-guests/' + value.id;
      this.$http.delete(uri)
          .then((response) => {
            this.getWedding(),
                this.$toast.success(response.data.message, "Success")
          });
    },
    destroyWedding(value) {

      this.$toast.question('Are you sure about that?', "Confirm", {
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: 'once',
        id: 'product',
        zindex: 999,
        title: 'Hey',
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', (instance, toast) => {

            this.deleteWedding(value);

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }, true],
          ['<button>NO</button>', function (instance, toast) {

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');
          }],
        ]
      });
    },
    destroy(value) {

      this.$toast.question('Are you sure about that?', "Confirm", {
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: 'once',
        id: 'product',
        zindex: 999,
        title: 'Hey',
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', (instance, toast) => {

            this.removeWeddingGuest(value);

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }, true],
          ['<button>NO</button>', function (instance, toast) {

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');
          }],
        ]
      });
    },
    updateWedding(value) {
      this.people = false;
      this.edit = false;

      let uri = '/api/events/' + value.id;
      this.$http.patch(uri, value)
          .then((response) => {
            this.$toast.success(response.data.message, "Success")
          });
    },
    getTypes() {
      let uri = '/api/event-guests/type';
      this.$http.get(uri).then((response) => {
        this.types = response.data.data
      });
    },
    refreshData(type) {
      console.log(type);
      if (type == 1 || type == 2) {
        this.getWedding();
      } else {
        this.getWeddingGuests();
      }
    },
    getWedding() {
      console.log('getWedding called');
      this.loading = true;
      //let uri = '/api/events/' + this.$route.params.id;
      //let uri = '/api/events/1';
      let uri = '/api/events';
      this.$http.get(uri)
          .then(({data}) => {
            this.wedding = data.data;
          })
          .catch(error => {
            console.log('Error loading data: ' + error)
          }).finally(() => {
            this.loading = false;
            console.log("getWedding");
          }
      )
    },
    getWeddingGuests() {
      let uri = '/api/events';
      this.$http.get(uri)
          .then(({data}) => {
            this.wedding.guests = data.data.guests;
          })
          .catch(error => {
            console.log('Error loading data: ' + error)
          })
    },
    sendWebPageToAll(guest) {
      let uri = '/api/event-guests/webpage';
      this.$http.post(uri, guest)
          .then((response) => {
            this.getWeddingGuests(),
                this.$toast.success(response.data.message, "Success")
          });
    },
    /*
   getWeddingGuests () {
    this.loading = true;
    this.$http.get('api/event-guests', { params: this.$route.query })
        .then(({data})  => {
            this.wedding_guests = data.data
        })
        .catch(error => {
            console.log('Error loading orders data: ' + error)
        }).finally(() =>
        this.loading = false,
        )
    },
    getNewlyweds () {
    this.loading = true;
    this.$http.get('api/event-guests/newlyweds', { params: this.$route.query })
        .then(({data})  => {
            this.newlyweds = data.data
        })
        .catch(error => {
            console.log('Error loading orders data: ' + error)
        }).finally(() =>
        this.loading = false,
        )
    },
   getWeddings () {
    this.loading = true;
    this.$http.get('api/events', { params: this.$route.query })
        .then(({data})  => {
            this.weddings = data.data
        })
        .catch(error => {
            console.log('Error loading orders data: ' + error)
        }).finally(() =>
        this.loading = false,
        )
    },
    */
  }
}
</script>







